import React from "react"

import Layout from "../components/layout"

import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title='Cinema Garden' />
  </Layout>
)

export default IndexPage
